import React from 'react';
import styled from 'styled-components';

import { colors } from '@marvelapp/theme';
import { Box, Heading, Text } from '@marvelapp/ui';

import Page from '../components/Page';
import PageMeta from '../components/PageMeta';

export default function BlogIndex({ location }) {
  return (
    <Page location={location}>
      <PageMeta title="BDD docs" />

      <Heading as="h1" fontSize={[6, 7]} marginTop={[6, 7, 104]}>
        BDD docs
      </Heading>
      <Text fontSize={[18, 22]} lineHeight={1.7}>
        At Marvel we follow Behaviour-Driven Development (BDD) principles. BDD
        helps to close the gap between business people and technical people,
        which is achieved by focusing on real-life usage examples that
        illustrate how we want our app to behave.
      </Text>

      <Box bg="smoke" height="1px" my={[5, 6]} width={1} />

      <Text fontSize={[3, 4]} lineHeight={1.7} mb={4}>
        BDD provides human readable <InlineCode>.feature</InlineCode> files that
        can be understood by everyone on the team and double up as documentation
        of how the features are supposed to work. We then use them to guide us
        from concept through to implementation, making sure that everyone is on
        the same page and that we cover all possible usage use cases.
      </Text>

      <Text fontSize={[3, 4]} lineHeight={1.7}>
        This site acts as a directory of features, which can be used as a
        reference for behaviour of Marvel App features.
      </Text>
    </Page>
  );
}

const InlineCode = styled.code`
  color: ${colors.redDark};
  background: ${colors.snow};
  padding: 2px 3px;
  border-radius: 3px;
  border: 1px solid ${colors.snowExtraDark};
`;
