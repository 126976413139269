import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
function Heading({ children, ...props }) {
    return (React.createElement(Text, Object.assign({ color: "licorice", fontSize: 5, fontWeight: "600", lineHeight: 1.15 }, props), children));
}
Heading.propTypes = {
    children: PropTypes.any,
};
export default Heading;
